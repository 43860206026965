import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, Label, Select } from '@fluentui/react-components'
import { Dispatch, useEffect, useRef, useState } from 'react'
import { GridLoader } from 'react-spinners'
import { Document, getCollectionAndDocuments, getDocumentContent, getEmbeddings, reindexDocument } from '../api'
import css from './DocumentDialog.module.scss'


type Props = {
  isOpen: boolean
  setOpen: Dispatch<boolean>
  document: Document
}

export function DocumentDialog({ isOpen, setOpen, document }: Props) {
  const divRef = useRef<HTMLDivElement | null>(null)
  const [ready, setReady] = useState(false)
  const primaryColour = getComputedStyle(window.document.documentElement).getPropertyValue('--theme-primary-color')

  useEffect(() => {
    async function loadContent() {
      const text = await getDocumentContent(document.id)
      const div = divRef.current
      if (div) {
        div.innerHTML = text
      }
      setReady(true)
    }
    loadContent()
  }, [document.id])


  useEffect(() => {
    async function loadEmbeddings() {
      const embeddings = await getEmbeddings(document.id)
      const div = divRef.current
      if (div) {
        Array.from(div.children).forEach(elem => {
          const marker = window.document.createAttribute('data-marker')
          marker.value = 'true'
          elem.setAttributeNode(marker)
        })
        for (const embedding of embeddings) {
          const matches = findMatches(embedding.source, div)
          if (matches.length > 0) {
            const match = matches[0]
            match.classList.add(css.embeddingHighlight)
            const token = window.document.createElement('div')
            token.classList.add(css.embeddingToken)
            token.innerText = embedding.tokenLength.toString()
            match.appendChild(token)
            match.removeAttribute('data-marker')
          }
        }
        div.querySelectorAll('[data-marker]').forEach(elem => {
          elem.classList.add(css.removedHighlight)
        })
      }
    }
    if (ready && divRef.current) {
      loadEmbeddings()
    }
  }, [document.id, ready])

  return <Dialog modalType='non-modal' open={isOpen} onOpenChange={(event, data) => setOpen(data.open)}>
    <DialogSurface className={css.dialogSurface} aria-describedby={undefined}>
      <DialogBody>
        <DialogTitle>{document.name}</DialogTitle>
        <DialogContent className={css.dialogContent}>

          {!ready && <GridLoader color={primaryColour} />}
          {ready && <div ref={divRef} />}
        </DialogContent>
      </DialogBody>
    </DialogSurface>
  </Dialog>
}

function findMatches(htmlFragment: string, element: HTMLElement): Element[] {

  let matching: Element[] = []
  const doc = new DOMParser().parseFromString(htmlFragment, 'text/html')
  if (!doc.body.firstElementChild) {
    // DOMParser()) collapses table elements not in <table> to just text node
    if (htmlFragment.startsWith('<tr')) {
      const matchingTags = element.querySelectorAll('tr')
      matching = Array.from(matchingTags).filter((c) => {
        if (c.nodeType === Node.ELEMENT_NODE) {
          const elem = c as HTMLElement
          return elem.textContent?.toLowerCase().includes(doc.body.innerHTML.toLowerCase())
        }
        return false
      })
      return matching
    }
  }

  const searchElement = doc.body.firstElementChild as HTMLElement
  const matchingTags = element.querySelectorAll(searchElement.tagName)

  matching = Array.from(matchingTags).filter((c) => {
    if (c.nodeType === Node.ELEMENT_NODE) {
      const elem = c as HTMLElement
      return elem.textContent?.toLowerCase().includes(searchElement.textContent?.toLowerCase() ?? '')
    }
    return false
  })

  return matching
}