import { createTableColumn } from '@fluentui/react-components'
import React from 'react'
import { KeysWithValsOfType } from './types'


export function createStringTableColumn<TItem, TValue>(title: string, key: KeysWithValsOfType<TItem, TValue>, sortFn?: (a: TItem, b: TItem) => number,  renderFn?: (item: TItem) => React.ReactNode) {
  return createTableColumn<TItem>({
    columnId: key as string,
    compare: (a: TItem, b: TItem) => sortFn ? sortFn(a, b) : (a[key] as string).localeCompare(b[key] as string),
    renderHeaderCell: () => {
      return title
    },
    renderCell: (item) => {
      return renderFn ? renderFn(item) : (item[key] as string)
    },
  })
}

export function createNumberTableColumn<TItem>(title: string, key: KeysWithValsOfType<TItem, number>) {
  return createTableColumn<TItem>({
    columnId: key as string,
    compare: (a, b) => {
      return (a[key] as number) - (b[key] as number)
    },
    renderHeaderCell: () => {
      return title
    },
    renderCell: (item) => {
      return item[key] as number
    },
  })
}