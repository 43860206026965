export function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export function createPromiseSource<T = never>(): PromiseSource<T> {
  let resolve: undefined | ((value: T | PromiseLike<T>) => void)
  let reject: undefined | ((reason?: any) => void)

  const promise = new Promise<T>((res, rej) => {
    resolve = res
    reject = rej
  })

  return { promise, resolve: resolve!, reject: reject! }
}

export interface PromiseSource<T = never> {
  promise: Promise<T>
  resolve: (value: T | PromiseLike<T>) => void
  reject: (value?: any) => void
}