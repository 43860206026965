import { AuthenticationResult } from '@azure/msal-browser'
import { UserInfo } from '../model'
import { DocumentIndexStatus, IndexingSessionStatus } from '../store/sagas/notifications'
import { createEmitter } from '../utils/event'
import { createBearerTokenEnricher, ensureSuccess, postInit, withEnricher } from '../utils/network'
import azureAdClient, { AzureAdClient } from './azure-ad'


export type BaseLayerModel = {
  id: string
  name: string
  type: string
  version: number
  rootLayer: LayerModel
}

export type LayerModel = {
  attributes: any
  subLayers: LayerModel[]
}

export async function getModelsAndLayers() {
  return executeResult<BaseLayerModel[]>('/api/layerModels')
}

export async function updateLayer(data: { baseLayerName: string, subLayerName: string, apiDescription?: string }) {
  const { baseLayerName, ...rest } = data
  return execute(`/api/layerModels/${baseLayerName}/layer`, postInit(rest))
}

export type Application = {
  id: string
  organisationId: string
  name: string
  created: number
}

export async function getApplications() {
  return executeResult<Application[]>('/api/applications')
}

export type DocumentType = 'Text' | 'Html' | 'Pdf'

export interface Document {
  id: string
  localId: string
  name: string
  documentType: DocumentType
  collectionId: string
  content: string | null
  contentUri: string | null
  created: number
  lastSync: number | null
  embeddingCount: number
}

export type CollectionAndDocs = {
  collection: Collection
  documents: Document[]
}

export async function getCollectionAndDocuments(collectionId: string) {
  return executeResult<CollectionAndDocs>(`/api/collections/${collectionId}/documents`)
}

export type Collection = {
  id: string
  applicationId: string
  name: string
  created: number
  docCount: number
  latestIndexing: IndexingSession | null
}

export type IndexingSession = {
  id: string
  collectionId: string
  progressMessage: string
  created: number,
  completed: number | null
  status: IndexingSessionStatus
  sessionData: {
    documentsToIndex: {
      documentId: string
      localId: string,
      status: DocumentIndexStatus,
      statusMessage: null,
      added: number,
      removed: number,
      unchanged: number,
    }[]
  },
  fullIndex: boolean
}

export async function postCollections(collection: Omit<Collection, 'id' | 'created' | 'docCount' | 'latestIndexing'>) {
  return await executeResult('/api/collections', postInit(collection))
}

export async function getCollections() {
  return executeResult<Collection[]>('/api/collections')
}

export async function deleteCollection(id: string) {
  return execute(`/api/collections/${id}`, { method: 'DELETE' })
}

export async function reindexCollection(collectionId: string) {
  return execute(`/api/collections/${collectionId}/index`, { method: 'POST' })
}

export interface DocumentIndexResource {

}
export interface IndexingRequest {
  collectionId: string
  documents?: DocumentIndexResource[]
  fullIndex: boolean
}

export async function reindexDocument(collectionId: string, documentId: string) {
  return execute(`/api/collections/${collectionId}/document/${documentId}/index`, { method: 'POST' })
}

export interface Embedding {
  id: string
  source: string
  sourceHash: number
  tokenLength: number
}

export async function getEmbeddings(documentId: string) {
  return executeResult<Embedding[]>(`/api/documents/${documentId}/embeddings`)
}

export async function getDocumentContent(documentId: string) {
  const resp = await execute(`/api/documents/${documentId}/content`)
  const text = await resp.text()
  return text
}

export async function executeResult<T>(url: string, init?: RequestInit) {
  const resp = await execute(url, init)
  return await resp.json() as T
}

export function getBearerTokenEnricher() {
  return createBearerTokenEnricher(() => acquireToken('api'))
}

export function makeUrl(urlPath: string) {
  return `${process.env.PUBLIC_URL}${urlPath}`
}

export async function execute(url: string, init?: RequestInit) {
  const resp = await fetch(makeUrl(url), await withEnricher(getBearerTokenEnricher(), init))
  await ensureSuccess(resp)
  return resp
}

export type TokenType = 'api' | 'graph'

const AzureAdClients: Partial<Record<TokenType | '-', AzureAdClient>> = {}
let acquireTokenPromise: Promise<{ type: TokenType, token: string }> | undefined
const tokenRefreshedEvent = createEmitter<[]>()

export function acquireToken(type: TokenType, appId?: string) {
  if (acquireTokenPromise) {
    return (async () => {
      const promiseResult = await acquireTokenPromise
      if (promiseResult.type === type) {
        return promiseResult.token
      }

      const client = createAzureAdClient(type, appId)
      const res = await client.acquireSilent()
      if (!res) {
        throw new Error('Token aquisition failed')
      } else {
        return getToken(client, res)
      }
    })()
  }

  acquireTokenPromise = (async () => {
    const client = createAzureAdClient(type)

    let res = await client.acquireSilent()
    if (!res) {
      console.warn('Acquiring', type, 'token silently failed. Retrying with a popup')
      res = await client.acquirePopup()
    }

    return {
      type,
      token: getToken(client, res),
    }
  })()

  return acquireTokenPromise.then(res => {
    tokenRefreshedEvent.emit()
    return res
  }).then(res => res.token).finally(() => {
    acquireTokenPromise = undefined
  })

  function getToken(client: AzureAdClient, res: AuthenticationResult) {
    return res.accessToken
  }
}


export function createAzureAdClient(type?: TokenType, appId?: string) {
  const key = type ?? '-'
  let client = AzureAdClients[key]
  if (client) { return client }

  client = azureAdClient({
    clientId: appId ?? globalConfig.clientId,
    redirectUrl: makeUrl('/blank'),
    scopes: type === undefined ? [] : getScopes(type),
    authority: 'https://login.microsoftonline.com/' + globalConfig.tenantId,
  })

  AzureAdClients[key] = client
  return client
}

function getScopes(type: TokenType) {
  switch (type) {
    case 'api': return globalConfig.scopes
    case 'graph': return ['Calendars.ReadWrite', 'User.Read', 'User.ReadBasic.All']
  }
}

export function parseToken(token: string): UserInfo {
  console.log('parseToken', token)
  const [, data] = token.split('.')
  const user: UserInfo = JSON.parse(atob(data))
  return user
}
