import React, { Dispatch, useState } from 'react'
import LoginPage from './components/LoginPage'
import { useSelector } from './store'
import { selectUser } from './store/reducers/login'
import css from './App.module.scss'
import { Tab, TabList } from '@fluentui/react-components'
import { Home20Regular, Organization20Regular, BookmarkMultiple20Regular, Branch20Regular } from '@fluentui/react-icons'
import { CollectionsPage } from './components/CollectionsPage'
import { ModelsPage } from './components/ModelsPage'

export default function App() {
  const [activeTab, setActiveTab] = useState('collections')
  const user = useSelector(selectUser)
  return <div className={css.app} >
    {render()}
  </div>

  function render() {
    if (!user) {
      return <LoginPage />
    }

    return (
      <>
        <Topbar />
        <div className={css.main}>
          <Sidebar activeTab={activeTab} onChange={t => setActiveTab(t)}/>
          {activeTab === 'home' && <div>Home</div>}
          {activeTab === 'organisations' && <div>Organisations</div>}
          {activeTab === 'collections' && <CollectionsPage />}
          {activeTab === 'models' && <ModelsPage />}
        </div>
      </>
    )
  }
}

function Topbar() {
  return <div className={css.topbar}>
    <h1>IPFX Q & A Management Portal</h1>
  </div>
}

function Sidebar({ activeTab, onChange }: { activeTab: string, onChange: Dispatch<string> }) {
  return <nav className={css.sidebar}>
    <TabList defaultSelectedValue={activeTab}
      onTabSelect={(e, d) => onChange(d.value as string)}
      size='large'
      appearance='subtle'
      vertical>
      <Tab value='home'>
        <div className={css.tabContent}>
          <Home20Regular />Home
        </div>
      </Tab>
      <Tab value='organisations'>
        <div className={css.tabContent}>
          <Organization20Regular />Organisations
        </div>
      </Tab>
      <Tab value='collections'>
        <div className={css.tabContent}>
          <BookmarkMultiple20Regular />Collections
        </div>
      </Tab>
      <Tab value='models'>
        <div className={css.tabContent}>
          <Branch20Regular />Models
        </div>
      </Tab>
    </TabList>
  </nav>
}
