import { AuthenticationResult, AuthError, BrowserAuthErrorMessage } from '@azure/msal-browser'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createAzureAdClient, parseToken } from '../api'
import { UserInfo } from '../model'
import { actions, useSelector } from '../store'
import { createBearerTokenEnricher, ensureSuccess, HttpError, postInit } from '../utils/network'
import { stringsEqualIgnoreCase } from '../utils/text'
import css from './LoginPage.module.scss'

export default function LoginPage() {
    const dispatch = useDispatch()
    const [error, setError] = useState('')
    const [needLogin, setNeedLogin] = useState(false)
    const [showReload, setShowReload] = useState(false)

    const handleResult = useCallback(async (result: AuthenticationResult) => {
        try {
            const { name, tid } = result.idTokenClaims as Record<string, string>
            const authRes = {
                oid: result.uniqueId,
                name: name,
                upn: result.account?.username ?? '',
                token: {
                    accessToken: result.accessToken,
                    expiresOn: result.expiresOn,
                },
            }
            const init = postInit({ oid: authRes.oid, tenantId: tid })
            await createBearerTokenEnricher(async () => result.accessToken)(init)

            const resp = await fetch(`${process.env.PUBLIC_URL}/api/login`, init)
            await ensureSuccess(resp)


            const { organisationId, organisationName } = await resp.json() as { organisationId: string, organisationName: string }

            const userInfo: UserInfo = {
                ...authRes,
                organisationId,
                organisationName,
            }

            dispatch(actions.login(userInfo))
        } catch (e) {
            if (e instanceof HttpError) {
                if (e.status === 403) {
                    setError('This user is not allowed to login')
                    return
                }
            }

            setShowReload(true)
            setError('An server error occured\n' + (e as Error).message)
        }
    }, [dispatch])

    useEffect(() => {
        let valid = true
        onLoad()
        return () => { valid = false }

        async function onLoad() {
            const client = createAzureAdClient('api')
            const res = await client.acquireSilent()
            console.log('acquireSilent', res)
            if (!valid) { return }

            if (res) {
                handleResult(res)
            } else {
                setNeedLogin(true)
            }
        }
    }, [dispatch, handleResult])

    async function onSubmit(e: React.FormEvent) {
        e.preventDefault()
        setNeedLogin(false)

        try {
            const client = createAzureAdClient('api')
            const res = await client.acquirePopup()
            handleResult(res)
        } catch (e) {
            const authError = e as AuthError
            if (authError.errorCode === BrowserAuthErrorMessage.userCancelledError.code) {
                setNeedLogin(true)
            } else {
                setError((e as Error).message ?? 'Error')
            }
        }
    }

    function reload() {
        window.location.reload()
    }

    return <form className={css.loginPage} onSubmit={onSubmit}>
        {needLogin && <button>Login with Azure AD</button>}
        {showReload && <button type='button' onClick={reload}>Reload</button>}
        {error ? <div className={css.error}>{error}</div> : needLogin ? null : 'Waiting for login...'}
    </form>
}
