import { createPromiseSource, delay } from '../utils/promise'
import * as signalR from '@microsoft/signalr'


export type BaseSignalRHandler = {
  onConnected(reconnect: boolean): void
  onDisconnected(err: Error | undefined): void
  onReconnecting(err: Error | undefined): void
  onReconnected(): void
  registerHandlers: (connection: signalR.HubConnection) => void
}

export type SignalROpts = {
  debugLogging?: boolean
  hubUrl: string
  diagnosticTag: string
}

export default async function createSignalRConnection<THandler extends BaseSignalRHandler>(getToken: () => Promise<string>,  opts: SignalROpts, handler: THandler) {

  let connection: signalR.HubConnection
  let wasConnected = false
  let connected = createPromiseSource<null>()

  const builder = new signalR.HubConnectionBuilder()
    .withUrl(opts.hubUrl, { accessTokenFactory: getToken })
    .withAutomaticReconnect()
  if (opts.debugLogging) {
    builder.configureLogging(signalR.LogLevel.Debug)
  }
  connection = builder.build()

  handler.registerHandlers(connection)

  connection.onreconnecting(err => {
    console.log('info', `${opts.diagnosticTag}: connection reconnecting`, err)
    connected = createPromiseSource<null>()
    handler.onReconnecting(err)
  })

  connection.onreconnected(() => {
    console.log('info', `${opts.diagnosticTag}: connection reconnected`)
    connected.resolve(null)

  })

  connection.onclose(err => {
    console.log('info', `${opts.diagnosticTag}: connection closed (wasConnected: ${{ wasConnected }})`, err)
    //emitEvent('presence-disconnected', 'Presence server connection disconnected: {userName}', {}, err?.toString())
    connected = createPromiseSource<null>()
    handler.onDisconnected(err)
  })

  await connection.start()
  connected.resolve(null)
  handler.onConnected(wasConnected)
  connected.resolve(null)
  console.info(`${opts.diagnosticTag}: connected`)

  async function invoke<TReturn>(methodName: string, ...args: any[]): Promise<TReturn> {
    await connected.promise
    return connection.invoke(methodName, ...args)
  }

  return { stop: () => connection.stop(), invoke }
}
