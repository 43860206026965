import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, Label, Select } from '@fluentui/react-components'
import { DispatchWithoutAction, PropsWithChildren } from 'react'

type Props = {
  title: string
  positiveButtonText?: string
  negativeButtonText?: string
  onPositive: DispatchWithoutAction
  onNegative: DispatchWithoutAction
}

export function ConfirmDialog({ title, positiveButtonText, negativeButtonText, onPositive, onNegative, children }: PropsWithChildren<Props>) {
  return <Dialog modalType='modal' open={true}>
    <DialogSurface aria-describedby={undefined}>
      <DialogBody>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button appearance="secondary" onClick={() => onNegative()}>{negativeButtonText ?? 'Cancel'}</Button>
          </DialogTrigger>
          <Button appearance="primary" onClick={() => onPositive()}>
            {positiveButtonText ?? 'OK'}
          </Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
}