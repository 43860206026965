import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { actions } from '..'
import { Actions, StoreState } from '../types'

type CollectionState = Record<string, number>
function collections(state: CollectionState = {}, action: Actions): CollectionState {
  switch (action.type) {
    case getType(actions.refreshCollection):
      return { ...state, [action.payload]: (state[action.payload] ? state[action.payload] + 1 : 1) }
  }

  return state
}

function getRoot(state: StoreState) { return state.notifications }

export function selectCollections(state: StoreState) {
  return getRoot(state).collections
}

export function selectCollectionsVersion(state: StoreState, collectionIds: string[]) {
  const collections = selectCollections(state)
  return collectionIds.reduce((acc, id) => acc + (collections[id] ? collections[id] : 0), 0)
}

export default combineReducers({
  collections,
})
