import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Divider, Label, makeStyles, TableColumnDefinition, TableRowId, Toolbar, ToolbarButton, Tooltip } from '@fluentui/react-components'
import dayjs from 'dayjs'
import { useQuery, useQueryClient } from 'react-query'
import { Application, Document, getCollectionAndDocuments, reindexDocument } from '../api'
import Page from './Page'
import { DocumentSync32Regular, Dismiss24Regular, Delete24Regular, Copy24Regular, DocumentSync24Regular } from '@fluentui/react-icons'
import css from './CollectionPage.module.scss'
import { Dispatch, DispatchWithoutAction, useCallback, useEffect, useMemo, useState } from 'react'
import { ConfirmDialog } from './ConfirmDialog'
import { createNumberTableColumn, createStringTableColumn } from '../utils/dataTable'
import { useSelector } from '../store'
import { selectCollectionsVersion } from '../store/reducers/notifications'
import classNames from 'classnames'
import { LatestCell } from './CollectionsPage'
import { DocumentDialog } from './DocumentDialog'


type Item = Pick<Document, 'id' | 'name' | 'localId' | 'documentType' | 'embeddingCount'> & {
  createdTime: { timestamp: number, label: string }
  lastIndexed: { timestamp: number | null, label: string }
  data: Document,
}


const getColumns = (onShow: (item: Item) => void): TableColumnDefinition<Item>[] => {
  return [
    createStringTableColumn('Name', 'name', undefined, (item) => <NameCell item={item} onClick={i => { onShow(i) }} />),
    createStringTableColumn('LocalId', 'localId'),
    createNumberTableColumn('Embedding #', 'embeddingCount'),
    createStringTableColumn('Type', 'documentType'),
    createStringTableColumn('Last Indexed', 'lastIndexed', (a, b) => a.lastIndexed.timestamp ?? 0 - (b.lastIndexed.timestamp ?? 0), item => item.lastIndexed.label),
    createStringTableColumn('Creaated', 'createdTime', (a, b) => a.createdTime.timestamp ?? 0 - (b.createdTime.timestamp ?? 0), item => item.createdTime.label),
  ]
}

type Props = {
  collectionId: string
  onClose: DispatchWithoutAction
  className?: string
}

export function CollectionPage({ collectionId, className, onClose }: Props) {
  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery('documents', async () => {
    const query = await getCollectionAndDocuments(collectionId)
    const docs = query.documents.map<Item>(d => {
      const last = query.collection.latestIndexing?.sessionData.documentsToIndex.find(e => e.documentId === d.id)
      return {
        id: d.id,
        name: d.name,
        documentType: d.documentType,
        localId: d.localId,
        embeddingCount: d.embeddingCount,
        createdTime: { timestamp: d.created, label: dayjs(d.created).local().fromNow() },
        lastIndexed: { timestamp: d.lastSync, label: d.lastSync ? `${dayjs(d.lastSync).local().fromNow()}${last ? `(added: ${last.added}, removed: ${last.removed}), unchanged: ${last.unchanged})` : ''}` : 'Never' },
        data: d,
      }
    })

    console.log(`LastIndexed`, query.collection.latestIndexing)

    return [query, docs] as const
  })
  const [showDocument, setShowDocument] = useState<Document | null>(null)

  const handleNameClick = useCallback((i: Item) => {
    setShowDocument(i.data)
    console.log(`Name click`, i.data)
  }, [])

  const collectionsVersion = useSelector(s => selectCollectionsVersion(s, data ? [data[0]!.collection.id] : []))
  const columns = useMemo(() => getColumns(handleNameClick), [handleNameClick])

  useEffect(() => {
    console.log(`Collections have changed: verion=${collectionsVersion}`)
    queryClient.invalidateQueries('collections')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsVersion])

  const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>([]))
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const selectedItem = useMemo(() => {
    if (selectedRows.size !== 1) return null
    const docId = Array.from(selectedRows.keys())[0] as string
    return data?.[1].find(c => c.id === docId) ?? null

  }, [data, selectedRows])

  const onSelectionChange: DataGridProps['onSelectionChange'] = (e, data) => {
    setSelectedRows(data.selectedItems)
  }

  const handleConfirmDelete = () => {
    setShowConfirmDelete(true)
  }

  const handleDelete = async () => {
    setShowConfirmDelete(false)
    const ids = Array.from(selectedRows.keys()).map(e => e as string)
    // deleteRange.mutate(ids)
    setSelectedRows(new Set<TableRowId>([]))
  }

  const handleReIndex = async () => {
    if (!selectedItem) return
    await reindexDocument(selectedItem.data.collectionId, selectedItem.id)
  }

  return <Page className={classNames(css.collectionPage, className)}>
    <div className={css.pageTitleRow}>
      <DocumentSync32Regular />
      <h2>Collection</h2>
      <Button className={css.close} onClick={onClose} icon={<Dismiss24Regular />} />
    </div>
    {data && <div className={css.details}>
      <Field label='Name' value={data[0].collection.name} />
      <Field label='Id' value={data[0].collection.id} />
      <Field label='Latest' value={<LatestCell latestIndexing={data[0].collection.latestIndexing} />} />
    </div>}
    <Divider className={css.divider} />
    <Toolbar className={css.toolbar}>
      <ToolbarButton
        aria-label="Delete"
        appearance='subtle'
        disabled={selectedRows.size === 0}
        onClick={() => handleConfirmDelete()}
        icon={<Delete24Regular />}>Delete</ToolbarButton>
      <ToolbarButton
        aria-label="Re-Index"
        appearance='subtle'
        disabled={selectedItem === null} // || isSelectedIndexing}
        onClick={() => handleReIndex()}
        icon={<DocumentSync24Regular />}>Re-Index</ToolbarButton>
    </Toolbar>
    <DataGrid
      items={data ? data[1] : []}
      getRowId={(item) => item.data.id}
      columns={columns}
      sortable
      selectionMode="multiselect"
      selectedItems={selectedRows}
      onSelectionChange={onSelectionChange}
    >
      <DataGridHeader>
        <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell className={css.headerCell}>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<Item>>
        {({ item, rowId }) => (
          <DataGridRow<Item>
            key={rowId}
            selectionCell={{ 'aria-label': 'Select row' }}
          >
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>

    {showConfirmDelete && <ConfirmDialog title="Delete collection"
      positiveButtonText='Delete'
      negativeButtonText='Cancel'
      onNegative={() => setShowConfirmDelete(false)}
      onPositive={() => handleDelete()}
    >
      <p>Are you sure you want to delete the selected collections?</p>
    </ConfirmDialog>}
    {showDocument && <DocumentDialog document={showDocument} isOpen={true} setOpen={o => setShowDocument(null)} />}
  </Page >
}

type NewCollectionDialogProps = {
  applications: Application[]
  isOpen: boolean
  setOpen: Dispatch<boolean>
  onSubmit: Dispatch<{ name: string, applicationId: string }>
}

export function Field({ label, value }: { label: string, value: string | React.ReactNode }) {
  return <div className={css.field}>
    <Label className={css.label}>{label}</Label>
    {typeof value === 'string' ? <span className={css.value}>{value}</span> : value}
  </div>
}


export function NameCell<T extends { name: string }>({ item, onClick }: { item: T, onClick: (item: T) => void }) {
  function handleClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault()
    e.stopPropagation()
    onClick(item)
  }
  return <div className={css.nameCell}>
    <button onClick={e => handleClick(e)} className={css.linkButton}>{item.name}</button>
  </div>
}

