import { Button, Combobox, Option, Divider, Label, ComboboxProps, Dropdown, Dialog, DialogSurface, DialogBody, DialogActions, DialogContent, DialogTitle, DialogTrigger, Input, Select, InputProps, Textarea, TextareaProps } from '@fluentui/react-components'
import { BaseLayerModel, LayerModel, getCollections, updateLayer } from '../api'
import Page from './Page'
import { Dismiss24Regular, Layer24Regular, Edit24Regular } from '@fluentui/react-icons'
import css from './LayerPage.module.scss'
import { DispatchWithoutAction, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'
import ReactPrismjs from '@uiw/react-prismjs'
import 'prismjs/themes/prism-okaidia.css'
import { useMutation, useQuery, useQueryClient } from 'react-query'

type Props = {
  parent: BaseLayerModel
  layer: LayerModel
  onClose: DispatchWithoutAction
  onRefresh: DispatchWithoutAction
  className?: string
}



export function LayerPage({ parent, layer, className, onClose, onRefresh }: Props) {
  const [editField, setEditField] = useState<{ text: string, onCommit: (text: string) => void } | null>(null)
  const [selectedOptions, setSelectedOptions] = useState<string[]>([])

  const queryClient = useQueryClient()

  const { isLoading, error, data: options } = useQuery('layerCollections', async () => {
    const collections = await getCollections()

    const included = layer.attributes.Collections?.map((col: any) => col.CollectionId) ?? []
    const selected = collections.filter(col => included.includes(col.id)).map(col => col.id)
    setSelectedOptions(selected)

    const opts = collections.map(c => ({
      key: c.id,
      text: c.name,
    }))
    console.log('options', opts, 'selected', selected)
    return opts
  })

  const update = useMutation(updateLayer, {
    onSuccess: () => {
      onRefresh()
    },
  })

  function handleUpdateApiDescription(text: string) {
    setEditField(null)
    update.mutate({ baseLayerName: parent.name, subLayerName: layer.attributes.LayerName as string, apiDescription: text })
  }

  const handleSelect: ComboboxProps['onOptionSelect'] = (event, data) => {
    setSelectedOptions(data.selectedOptions)
    console.log('selected', data.selectedOptions)
  }

  return <Page className={classNames(css.layerPage, className)}>
    <div className={css.pageTitleRow}>
      <Layer24Regular />
      <h2>Layer</h2>
      <Button className={css.close} onClick={onClose} icon={<Dismiss24Regular />} />
    </div>
    <div className={css.details}>
      <Field label='Name' value={layer.attributes.LayerName} />
      <Field label='Id' value={layer.attributes.Id} />
      <Field label='Type' value={layer.attributes.IntentHandlerType as string ?? (layer.attributes.LayerModel.Type as string ?? '(none)')} />
    </div>
    <Divider className={css.divider}>API</Divider>
    <div className={css.attributes}>
      <Field onEdit={() => setEditField({ text: layer.attributes.ApiDescription, onCommit: handleUpdateApiDescription })} label='Description' value={layer.attributes.ApiDescription} />
      {options && layer.attributes.LayerModel.Type === 'DynamicQuestionAnswerLayer' && <Field label='Collections' value={<Dropdown
        multiselect={true}
        placeholder='Select one or more collections'
        className={css.value}
        selectedOptions={selectedOptions}
        onOptionSelect={handleSelect}
      >
        {options.map((option) => (
          <Option key={option.key} value={option.key}>{option.text}</Option>
        ))}
      </Dropdown>} />}
      <Field label='Input' value={<Json src={layer.attributes.ApiInputDescription as string} />} />
      <Field label='Output' value={<Json src={layer.attributes.ApiOutputDescription as string} />} />
    </div>

    {editField && <EditTextField onOpen={(open) => !open && setEditField(null)} label='Description' initial={editField.text} onCommit={editField.onCommit} />}
  </Page >
}

function EditTextField({ onOpen, label, initial, onCommit }: { onOpen: (open: boolean) => void, label: string, initial: string, onCommit: (text: string) => void }) {
  const [value, setValue] = useState(initial)

  const onChange: TextareaProps['onChange'] = (event, data) => {
    setValue(data.value)
  }
  const valid = useMemo(() => value.length > 0, [value])

  return <Dialog modalType='modal' open={true} onOpenChange={(event, data) => onOpen(data.open)}>
    <DialogSurface aria-describedby={undefined}>
      <DialogBody>
        <DialogTitle>Edit property</DialogTitle>
        <DialogContent className={css.editTextBody}>
          <Label htmlFor={'edit-textarea'}>{label}</Label>
          <Textarea className={css.textarea} resize="both" id={'edit-textarea'} value={value} onChange={onChange} />
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button appearance="secondary" onClick={() => onOpen(false)}>Close</Button>
          </DialogTrigger>
          <Button appearance="primary" disabled={!valid} onClick={() => onCommit(value)}>
            Save
          </Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
}

function Json({ src }: { src: string }) {
  return <ReactPrismjs className={css.value} language='json' source={src} />
}

export function Field({ onEdit, label, value }: { onEdit?: () => void, label: string, value: string | React.ReactNode }) {
  return <div className={css.field}>
    <div className={css.label}>
      <Label>{label}</Label>
      {onEdit && <Button className={css.edit} onClick={() => onEdit?.()} icon={<Edit24Regular />} />}
    </div>
    {typeof value === 'string' ? <span className={css.value}>{value}</span> : value}
  </div>
}


