import { Button, createTableColumn, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, InputProps, Label, makeStyles, Select, SelectProps, Spinner, TableColumnDefinition, TableRowId, Toolbar, ToolbarButton, Tooltip } from '@fluentui/react-components'
import dayjs from 'dayjs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Application, Collection, getApplications, getCollections, postCollections, deleteCollection, IndexingSession, reindexCollection } from '../api'
import Page from './Page'
import { BookmarkMultiple32Regular, Add24Regular, Delete24Regular, Copy24Regular, DocumentSync24Regular } from '@fluentui/react-icons'
import css from './CollectionsPage.module.scss'
import { Dispatch, useEffect, useMemo, useState } from 'react'
import { ConfirmDialog } from './ConfirmDialog'
import { createNumberTableColumn, createStringTableColumn } from '../utils/dataTable'
import { DocumentIndexStatus, IndexingSessionStatus } from '../store/sagas/notifications'
import { useSelector } from '../store'
import { selectCollectionsVersion } from '../store/reducers/notifications'
import { PuffLoader } from 'react-spinners'
import { CollectionPage } from './CollectionPage'

type Props = {
}

type Item = Pick<Collection, 'id' | 'name' | 'docCount'> & {
  applicationName: string
  createdTime: { timestamp: number, label: string }
  latestIndexing: IndexingSession | null
  data: Collection,
}


const createColumns = (onShow: (id: string) => void): TableColumnDefinition<Item>[] => {
  return [
    createStringTableColumn('Name', 'name', undefined, (item) => <NameCell item={item} onClick={i => { onShow(i.id) }} />),
    createStringTableColumn('Application', 'applicationName'),
    createNumberTableColumn('Document count', 'docCount'),
    createStringTableColumn('Latest', 'latestIndexing', (a, b) => 0, (item) => <LatestCell latestIndexing={item.latestIndexing} />),
    createStringTableColumn('Created', 'createdTime', (a, b) => a.createdTime.timestamp - b.createdTime.timestamp, item => item.createdTime.label),
  ]
}

export function CollectionsPage() {
  const queryClient = useQueryClient()

  const { isLoading, error, data } = useQuery('collections', async () => {
    const query1 = getCollections()
    const query2 = getApplications()
    const [collections, applications] = await Promise.all([query1, query2])

    return [collections.map<Item>(c => ({
      id: c.id,
      name: c.name,
      applicationName: applications.find(a => a.id === c.applicationId)?.name ?? 'Unknown',
      createdTime: { timestamp: c.created, label: dayjs(c.created).utc(true).local().fromNow() },
      docCount: c.docCount,
      latestIndexing: c.latestIndexing,
      data: c,
    })), applications] as const
  })

  const collectionsVersion = useSelector(s => selectCollectionsVersion(s, data ? data[0].map(c => c.id) : []))

  useEffect(() => {
    console.log(`Collections have changed: verion=${collectionsVersion}`)
    queryClient.invalidateQueries('collections')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsVersion])

  const create = useMutation(postCollections, {
    onSuccess: () => {
      queryClient.invalidateQueries('collections')
    },
  })
  const deleteRange = useMutation(async (ids: string[]) => {
    const completeP = ids.map(async id => await deleteCollection(id))
    await Promise.all(completeP)
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries('collections')
    },
  })
  const [selectedRows, setSelectedRows] = useState(new Set<TableRowId>([]))
  const [showAdd, setShowAdd] = useState(false)
  const [showConfirmDelete, setShowConfirmDelete] = useState(false)
  const [showCollectionPage, setShowCollectionPage] = useState<string | null>(null)
  const selectedItem = useMemo(() => {
    if (selectedRows.size !== 1) return null
    const collId = Array.from(selectedRows.keys())[0] as string
    return data?.[0].find(c => c.id === collId) ?? null

  }, [data, selectedRows])
  const isSelectedIndexing = useMemo(() => selectedItem !== null && selectedItem.latestIndexing !== null
    && !(selectedItem.latestIndexing.status === IndexingSessionStatus.Failed || selectedItem.latestIndexing.status === IndexingSessionStatus.Success), [selectedItem])

  const columns = useMemo(() => createColumns(id => setShowCollectionPage(id)), [])

  const handleSubmit = (newCollection: { name: string, applicationId: string }) => {
    setShowAdd(false)
    create.mutate(newCollection)
  }

  const onSelectionChange: DataGridProps['onSelectionChange'] = (e, data) => {
    setSelectedRows(data.selectedItems)
  }

  const handleConfirmDelete = () => {
    setShowConfirmDelete(true)
  }

  const handleDelete = async () => {
    setShowConfirmDelete(false)
    const ids = Array.from(selectedRows.keys()).map(e => e as string)
    deleteRange.mutate(ids)
    setSelectedRows(new Set<TableRowId>([]))
  }

  const handleReIndex = async () => {
    if (!selectedItem) return
    await reindexCollection(selectedItem.id)
  }

  return <>
    {!showCollectionPage && <Page className={css.collectionsPage}>
      <div className={css.pageTitleRow}>
        <BookmarkMultiple32Regular />
        <h2>Collections</h2>
      </div>
      <Toolbar className={css.toolbar}>
        <ToolbarButton
          aria-label="New collection"
          appearance='subtle'
          onClick={() => setShowAdd(true)}
          icon={<Add24Regular />}>New collection</ToolbarButton>
        <ToolbarButton
          aria-label="Delete"
          appearance='subtle'
          disabled={selectedRows.size === 0}
          onClick={() => handleConfirmDelete()}
          icon={<Delete24Regular />}>Delete</ToolbarButton>
        <ToolbarButton
          aria-label="Re-Index"
          appearance='subtle'
          disabled={selectedItem === null || isSelectedIndexing}
          onClick={() => handleReIndex()}
          icon={<DocumentSync24Regular />}>Re-Index</ToolbarButton>
      </Toolbar>
      <DataGrid
        items={data ? data[0] : []}
        getRowId={(item) => item.data.id}
        columns={columns}
        sortable
        selectionMode="multiselect"
        selectedItems={selectedRows}
        onSelectionChange={onSelectionChange}
      >
        <DataGridHeader>
          <DataGridRow selectionCell={{ 'aria-label': 'Select all rows' }}>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell className={css.headerCell}>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
              selectionCell={{ 'aria-label': 'Select row' }}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>
      {
        showAdd && data && <NewCollectionDialog
          applications={data[1]}
          isOpen={showAdd}
          setOpen={o => setShowAdd(o)}
          onSubmit={handleSubmit} />
      }
      {showConfirmDelete && <ConfirmDialog title="Delete collection"
        positiveButtonText='Delete'
        negativeButtonText='Cancel'
        onNegative={() => setShowConfirmDelete(false)}
        onPositive={() => handleDelete()}
      >
        <p>Are you sure you want to delete the selected collections?</p>
      </ConfirmDialog>}
    </Page >}
    {showCollectionPage && <CollectionPage collectionId={showCollectionPage} onClose={() => setShowCollectionPage(null)} />}
  </>
}

type NewCollectionDialogProps = {
  applications: Application[]
  isOpen: boolean
  setOpen: Dispatch<boolean>
  onSubmit: Dispatch<{ name: string, applicationId: string }>
}

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '10px',
  },
})

function NewCollectionDialog({ applications, isOpen, setOpen, onSubmit }: NewCollectionDialogProps) {
  const [name, setName] = useState('')
  const [application, setApplication] = useState(applications[0].id)

  const valid = useMemo(() => {
    return name.length > 0
  }, [name])

  const onAppChange: SelectProps['onChange'] = (event, data) => {
    console.log('onAppChange', data)
    setApplication(data.value)
  }
  const onNameChange: InputProps['onChange'] = (event, data) => {
    setName(data.value)
  }
  const handleSubmit = () => {
    onSubmit({ name, applicationId: application })
  }

  const styles = useStyles()

  return <Dialog modalType='modal' open={isOpen} onOpenChange={(event, data) => setOpen(data.open)}>
    <DialogSurface aria-describedby={undefined}>
      <DialogBody>
        <DialogTitle>New Collection</DialogTitle>
        <DialogContent className={styles.content}>
          <Label required htmlFor={'name-input'}>
            Name
          </Label>
          <Input required id={'name-input'} value={name} onChange={onNameChange} />
          <Label required htmlFor={'application-select'}>
            Application
          </Label>
          <Select id={'application-select'} onChange={onAppChange} value={application}>
            {applications.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
          </Select>
        </DialogContent>
        <DialogActions>
          <DialogTrigger disableButtonEnhancement>
            <Button appearance="secondary">Close</Button>
          </DialogTrigger>
          <Button appearance="primary" disabled={!valid} onClick={() => handleSubmit()}>
            Submit
          </Button>
        </DialogActions>
      </DialogBody>
    </DialogSurface>
  </Dialog>
}

function NameCell({ item, onClick }: { item: Item, onClick: (item: Item) => void }) {
  function handleCopy(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.stopPropagation()
    navigator.clipboard.writeText(item.data.id)
  }
  return <div className={css.nameCell}>
    <button onClick={() => onClick(item)} className={css.linkButton}>{item.name}</button>
    <Tooltip content="Copy the id for this collection to the clipboard" relationship="label">
      <Button appearance='transparent' icon={<Copy24Regular />} onClick={(e) => handleCopy(e)} />
    </Tooltip>
  </div>
}

export function LatestCell({ latestIndexing }: { latestIndexing: IndexingSession | null }) {
  const inProgress = latestIndexing && !(latestIndexing.status === IndexingSessionStatus.Success || latestIndexing.status === IndexingSessionStatus.Failed)
  const latest = latestIndexing && getLastestIndexingMessage(latestIndexing)
  const primaryColour = getComputedStyle(document.documentElement).getPropertyValue('--theme-primary-color')

  return <div className={css.latestCell}>
    {inProgress && <PuffLoader size={16} className={css.spinner} color={primaryColour} />}
    {!latestIndexing && <span>Not indexed</span>}
    {latest && <span title={latest[1]}>{latest[0]}</span>}
  </div>
}

function getLastestIndexingMessage(session: IndexingSession): readonly [string, string | undefined] {
  const ago = (t: number) => dayjs(t).local().fromNow()
  switch (session.status) {
    case IndexingSessionStatus.Success:
      return [`Completed ${ago(session.completed!)}`, undefined]
    case IndexingSessionStatus.Failed:
      return [`Failed ${ago(session.completed!)}`, `Reason: ${session.progressMessage}`]
    case IndexingSessionStatus.Created:
      return ['Created request for indexing', undefined]
    case IndexingSessionStatus.Queued:
      return ['Queued request for indexing', undefined]
    case IndexingSessionStatus.IndexingDocs: {
      const complete = session.sessionData.documentsToIndex.filter(d => d.status === DocumentIndexStatus.Success || d.status === DocumentIndexStatus.Failed)?.length ?? 0
      const inprogress = session.sessionData.documentsToIndex.find(d => d.status === DocumentIndexStatus.Indexing)
      return [`Indexing document ${complete + 1} of ${session.sessionData.documentsToIndex.length}`, inprogress ? `Currently indexing document ${inprogress.documentId}` : undefined]
    }
  }
}
