import { all } from 'async-saga'
import { actions } from '..'
import { acquireToken, makeUrl } from '../../api'
import createSignalRConnection from '../../api/signalrConnection'
import { selectUser } from '../reducers/login'
import { SagaContext, takeEvery, waitForState } from './types'


export enum IndexingSessionStatus {
    Created = 'Created',
    Queued = 'Queued',
    IndexingDocs = 'IndexingDocs',

    Failed = 'Failed',
    Success = 'Success',
}

export enum DocumentIndexStatus {
    Queued = 'Queued',
    Indexing = 'Indexing',
    Failed = 'Failed',
    Success = 'Success',
}

export enum IndexOperationType {
  Embeddings = 'Embeddings',
  KnowledgeGraph = 'KnowledgeGraph',
}

export  type SessionChangedEvent = {
  collectionId: string
  sessionId: string
  status: IndexingSessionStatus
  operationType: IndexOperationType
}

export  type SessionDocumentChangedEvent = {
  collectionId: string
  sessionId: string
  documentId: string
  status: DocumentIndexStatus
  operationType: IndexOperationType
}

async function processNotifications(ctx: SagaContext) {
  const user = await waitForState(ctx, selectUser)
  const { invoke } = await createSignalRConnection(() => acquireToken('api'), { debugLogging: true, hubUrl: makeUrl('/notifications'), diagnosticTag: 'notifications' }, {
    registerHandlers(connection) {
      connection.on('NotifySessionChange', (notif: SessionChangedEvent) => {
        console.log('notifications: NotifySessionChange', notif)
        ctx.dispatch(actions.refreshCollection(notif.collectionId))
      })
      connection.on('NotifySessionDocumentChange', (notif: SessionDocumentChangedEvent) => {
        console.log('notifications: NotifySessionDocumentChange', notif)
        ctx.dispatch(actions.refreshCollection(notif.collectionId))
      })
    },
    onConnected(reconnect) {
      console.log(`notifications: onConnected(${reconnect})`)

    },
    onDisconnected(err) {
      console.log(`notifications: onDisconnected()`, err)
    },
    onReconnecting(err) {
      console.log(`notifications: onnReconnecting()`, err)
    },
    onReconnected() {
      console.log(`notifications: onnReconnected()`)
    },
  })

  console.log('notifications: createSignalRConnection() complete')
}

export default all(
  processNotifications,
)


