import { combineReducers } from 'redux'
import { getType } from 'typesafe-actions'
import { actions } from '..'
import { UserInfo } from '../../model'
import { Actions, StoreState } from '../types'

type State = UserInfo | null

function login(state: State = null, action: Actions): State {
    switch (action.type) {
        case getType(actions.login):
            return action.payload
    }

    return state
}

function getRoot(state: StoreState) { return state.login }

export function selectUser(state: StoreState) {
    return getRoot(state).login
}

export function selectUserId(state: StoreState) {
    return selectUser(state)?.oid
}
export default combineReducers({
    login,
})
