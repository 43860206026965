import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { configureStore } from './store'
import './index.scss'
import './themes.scss'
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components'
import { QueryClient, QueryClientProvider } from 'react-query'
import dayjs from 'dayjs'
import 'dayjs/locale/en-nz'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href')
const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)
const queryClient = new QueryClient()
const store = configureStore({})

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={baseUrl!}>
        <FluentProvider theme={teamsLightTheme}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </FluentProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

function applyProviders(...providers: React.ReactElement[]) {
  return providers.reduceRight((child, parent) => React.cloneElement(parent, { children: child }))
}