import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, Divider, TableColumnDefinition, TableRowId } from '@fluentui/react-components'
import { BaseLayerModel, LayerModel } from '../api'
import Page from './Page'
import { Dismiss24Regular, Branch24Regular } from '@fluentui/react-icons'
import css from './ModelPage.module.scss'
import { DispatchWithoutAction, useCallback, useMemo, useState } from 'react'

import { createStringTableColumn } from '../utils/dataTable'
import classNames from 'classnames'
import { Field, NameCell } from './CollectionPage'

type Item = {
  id: string
  name: string
  type: string
  data: LayerModel,
}

const getColumns = (onShow: (item: Item) => void): TableColumnDefinition<Item>[] => {
  return [
    createStringTableColumn('Name', 'name', undefined, (item) => <NameCell item={item} onClick={i => { onShow(i) }} />),
    createStringTableColumn('Type', 'type'),
  ]
}

type Props = {
  model: BaseLayerModel
  onClose: DispatchWithoutAction
  className?: string
  onShowLayer: (layer: LayerModel) => void
}

export function ModelPage({ model, onShowLayer, className, onClose }: Props) {

  const handleNameClick = useCallback((i: Item) => {
    onShowLayer(i.data)
  }, [onShowLayer])

  const columns = useMemo(() => getColumns(handleNameClick), [handleNameClick])

  const data = useMemo(() => {
    function makeItem(layer: LayerModel): Item {
      return {
        id: layer.attributes.Id as string,
        name: layer.attributes.LayerName as string,
        type: layer.attributes.IntentHandlerType as string ?? (layer.attributes.LayerModel.Type as string ?? '(none)'),
        data: layer,
      }
    }
    const base = makeItem(model.rootLayer)
    const subLayers = model.rootLayer.subLayers.map(makeItem)
    return [base, ...subLayers]
  }, [model.rootLayer])

  return <Page className={classNames(css.modelPage, className)}>
    <div className={css.pageTitleRow}>
      <Branch24Regular />
      <h2>Model</h2>
      <Button className={css.close} onClick={onClose} icon={<Dismiss24Regular />} />
    </div>
    <div className={css.details}>
      <Field label='Name' value={model.name} />
      <Field label='Id' value={model.id} />
      <Field label='Type' value={model.type} />
    </div>
    <Divider className={css.divider} />

    <DataGrid
      items={data}
      getRowId={(item) => item.data.id}
      columns={columns}
      sortable
    >
      <DataGridHeader>
        <DataGridRow>
          {({ renderHeaderCell }) => (
            <DataGridHeaderCell className={css.headerCell}>{renderHeaderCell()}</DataGridHeaderCell>
          )}
        </DataGridRow>
      </DataGridHeader>
      <DataGridBody<Item>>
        {({ item, rowId }) => (
          <DataGridRow<Item>
            key={rowId}
          >
            {({ renderCell }) => (
              <DataGridCell>{renderCell(item)}</DataGridCell>
            )}
          </DataGridRow>
        )}
      </DataGridBody>
    </DataGrid>
  </Page >
}


