type Listener<T extends any[]> = (...args: T) => void

export function createEmitter<T extends any[]>(): EventEmitter<T> {
  let curListeners: Listener<T>[] = []
  let nextListeners = curListeners

  function getNextListeners() {
    if (curListeners === nextListeners) {
      nextListeners = [ ...curListeners ]
    }
    return nextListeners
  }

  return {
    subscribe(listener: Listener<T>) {
      getNextListeners().push(listener)

      return function unsubscribe() {
        const listeners = getNextListeners()
        const index = listeners.indexOf(listener)
        if (index !== -1) {
          nextListeners.splice(index, 1)
        }
      }
    },

    emit(...args: T) {
      for (const listener of (curListeners = nextListeners)) {
        listener(...args)
      }
    },
  }
}

export type EventEmitter<T extends any[]> = {
  subscribe(listener: Listener<T>): () => void
  emit(...args: T): void
}
