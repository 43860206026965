
import { AuthenticationResult, AuthError, BrowserCacheLocation, InteractionRequiredAuthError, PublicClientApplication, RedirectRequest } from '@azure/msal-browser'
import { stringsEqualIgnoreCase } from '../utils/text'

interface AzureAdClientOptions {
  clientId: string
  scopes: string[]
  redirectUrl: string
  authority?: string
  onRedirected?(app: ReturnType<typeof createAzureAdClient>, response: AuthenticationResult | null, authErr: AuthError | undefined): void
  forceLogin?: boolean
  loginHint?: string
  cacheLocation?: BrowserCacheLocation
}

interface Options {
  forceLogin?: boolean
  loginHint?: string
}

const DefaultOptions: Options = { forceLogin: false, loginHint: undefined }

export default function createAzureAdClient({ clientId, scopes, authority, redirectUrl, onRedirected, cacheLocation }: AzureAdClientOptions) {
  const authRequestNoForce: RedirectRequest = {
    scopes,
    authority,
  }

  function getAuthRequest(opts: Options): RedirectRequest {
    let { forceLogin, loginHint } = opts
    if (!forceLogin && shouldLogin(opts)) {
      forceLogin = true
    }

    return {
      ...authRequestNoForce,
      loginHint,
      extraQueryParameters: forceLogin ? { max_age: '0', prompt: 'login' } : undefined,
    }
  }

  const app = new PublicClientApplication({
    auth: {
      clientId,
      authority,
      redirectUri: redirectUrl.startsWith('http') ? redirectUrl : window.location.origin + redirectUrl,
      navigateToLoginRequestUrl: false,
    },
    // system: { loggerOptions: { loggerCallback: (level, message) => console.log(level, message), logLevel: LogLevel.Trace } },
    cache: { cacheLocation: cacheLocation || 'localStorage' },
  })

  const me = {
    acquirePopup,
    acquireRedirect,
    acquireSilent,
    getIdToken,
  }

  let idToken: string | null = null

  handleRedirect()

  function handleResult(result: AuthenticationResult) {
    idToken = result.idToken ?? idToken
    return result
  }

  async function acquireSilent() {
    try {
      const accs = app.getAllAccounts()
      let request = authRequestNoForce
      if (accs.length === 1) {
        request = { ...request, account: accs[0] }
      }
      return handleResult(await app.acquireTokenSilent(request))
    } catch (error) {
      console.error(error)
      if (error instanceof InteractionRequiredAuthError) {
        return null
      }
      return null
    }
  }

  async function acquirePopup(opts = DefaultOptions) {
    if (shouldLogin(opts)) {
      const res = await app.loginPopup(getAuthRequest(opts))
      handleResult(res)
      app.setActiveAccount(res.account)
    }

    const silent = await acquireSilent()
    if (silent) { return silent }
    return handleResult(await app.acquireTokenPopup(getAuthRequest(opts)))
  }

  function acquireRedirect(opts = DefaultOptions) {
    if (shouldLogin(opts)) {
      app.loginRedirect(getAuthRequest(opts))
    } else {
      app.acquireTokenRedirect(getAuthRequest(opts))
    }
  }

  function getIdToken() {
    return idToken
  }

  async function handleRedirect() {
    try {
      const res = await app.handleRedirectPromise()
      if (res) {
        app.setActiveAccount(res.account)
        handleResult(res)
      }
      onRedirected?.(me, res, undefined)
    } catch (err) {
      onRedirected?.(me, null, err instanceof AuthError ? err : undefined)
    }
  }

  function shouldLogin({ forceLogin, loginHint }: Options) {
    if (forceLogin) {
      return true
    }

    const accounts = app.getAllAccounts()
    return accounts.length !== 1 || (loginHint && !stringsEqualIgnoreCase(accounts[0].username, loginHint))
  }

  return me
}

export type AzureAdClient = ReturnType<typeof createAzureAdClient>

export function isAzureAdCallback(hash: string = window.location.hash) {
  try {
    if (hash.startsWith('#')) {
      hash = hash.substr(1)
    }
    return new URLSearchParams(hash).has('state')
  } catch (e) {
    return false
  }
}
