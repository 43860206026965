import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridProps, DataGridRow, TableColumnDefinition, TableRowId, Tooltip } from '@fluentui/react-components'
import { useQuery, useQueryClient } from 'react-query'
import { BaseLayerModel, getModelsAndLayers, LayerModel } from '../api'
import Page from './Page'
import { Branch24Regular, Copy24Regular } from '@fluentui/react-icons'
import css from './ModelsPage.module.scss'
import { useCallback, useMemo, useState } from 'react'
import { createNumberTableColumn, createStringTableColumn } from '../utils/dataTable'
import { ModelPage } from './ModelPage'
import { NameCell } from './CollectionPage'
import { LayerPage } from './LayerPage'


type Item = Pick<BaseLayerModel, 'id' | 'name' | 'type' | 'version'> & {
  data: BaseLayerModel,
  numLayers: number
}


const getColumns = (onShow: (item: Item) => void): TableColumnDefinition<Item>[] => {
  return [
    createStringTableColumn('Name', 'name', undefined, (item) => <NameCell item={item} onClick={i => { onShow(i) }} />),
    createStringTableColumn('Type', 'type'),
    createNumberTableColumn('# Layers', 'numLayers'),
    createNumberTableColumn('Version', 'version'),
  ]
}


export function ModelsPage() {
  const [showModelPage, setShowModelPage] = useState<BaseLayerModel | null>(null)
  const [showLayerPage, setShowLayerPage] = useState<LayerModel | null>(null)

  const queryClient = useQueryClient()
  const { isLoading, error, data } = useQuery({
    queryKey: ['models'],
    queryFn: async () => {
      const query = await getModelsAndLayers()
      const layers = query.map<Item>(m => {
        return {
          id: m.id,
          name: m.name,
          type: m.type,
          version: m.version,
          numLayers: m.rootLayer.subLayers.length,
          data: m,
        }
      })
      return [query, layers] as const
    },
    onSuccess: (data) => {
      if (showModelPage && showLayerPage) {
        // update state to trigger child re-render
        const baseLayer = data[0].find(base => base.id === showModelPage.id)
        if (baseLayer) {
          const sub = baseLayer.rootLayer.subLayers.find(l => l.attributes.Id === showLayerPage.attributes.Id)
          if (sub) {
            setShowLayerPage(sub)
          }
        }
      }
    },
  })


  const handleNameClick = useCallback((i: Item) => {
    setShowModelPage(i.data)
  }, [])

  const handleLayerUpdate = () => {
    queryClient.invalidateQueries('models')
  }

  const columns = useMemo(() => getColumns(handleNameClick), [handleNameClick])

  return <>
    {!(showModelPage || showLayerPage) && <Page className={css.modelsPage}>
      <div className={css.pageTitleRow}>
        <Branch24Regular />
        <h2>Models</h2>
      </div>

      <DataGrid
        items={data ? data[1] : []}
        getRowId={(item) => item.data.id}
        columns={columns}
        sortable
      >
        <DataGridHeader>
          <DataGridRow>
            {({ renderHeaderCell }) => (
              <DataGridHeaderCell className={css.headerCell}>{renderHeaderCell()}</DataGridHeaderCell>
            )}
          </DataGridRow>
        </DataGridHeader>
        <DataGridBody<Item>>
          {({ item, rowId }) => (
            <DataGridRow<Item>
              key={rowId}
            >
              {({ renderCell }) => (
                <DataGridCell>{renderCell(item)}</DataGridCell>
              )}
            </DataGridRow>
          )}
        </DataGridBody>
      </DataGrid>

    </Page >}
    {showModelPage && !showLayerPage && <ModelPage model={showModelPage} onClose={() => setShowModelPage(null)} onShowLayer={l => setShowLayerPage(l)} />}
    {showLayerPage && showModelPage && <LayerPage layer={showLayerPage} parent={showModelPage} onClose={() => setShowLayerPage(null)} onRefresh={() => handleLayerUpdate()} />}
  </>
}

